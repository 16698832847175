@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;1,200&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactCollapse--collapse {
  transition: height 300ms;
  transition-duration: height 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bdbdbd;
}

body::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #33343f;
}

.background-dark {
  background: rgb(17, 24, 39);
  background: linear-gradient(
    0deg,
    rgba(17, 24, 39, 1) 50%,
    rgba(17, 24, 39, 0.9047280844155844) 100%
  );
}

.background-light {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(201, 201, 201, 0.9047280844155844) 100%
  );
}
